// colors
$white: #ffffff;
$black: #000000;

$lightgray: #f0f3f5;
$gray: #e2e7eb;
$darkgray: #242a30;

$lightblue: #d9edf7;
$blue: #337ab7;

$dl-blue: #0c5Fa5;
$dl-blue-lighter: #3073AB;
$dl-blue-darker: #084A80;

$dl-green: #70bc52;
$dl-green-lighter: #99DB81;
$dl-green-darker: #4E9F30;

$dl-red: #FF0122;
$dl-red-lighter: #FF324E;
$dl-red-darker: #FF0023;
