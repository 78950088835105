// Use '~' to identify node_modules for Webpack

// Variables
@import "general/variables";

// Base Bootstrap and jQuery
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import '~datatables.net-bs/css/dataTables.bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// External components
@import '~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

// Custom CSS
@import "general/all";
@import "layout/all";
@import "partials/all";

@import "../css/custom.css";
